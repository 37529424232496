import { createStore } from 'zustand';
import { mutative } from 'zustand-mutative';
import { SegmentData } from 'types/post-types/PostTypes';
import { LandingPageStore } from './types';

export const createLandingPageStore = (
  preloadedState: Pick<LandingPageStore, 'contentfulData' | 'promotions'>,
) => {
  return createStore(
    mutative<LandingPageStore>((set) => {
      return {
        // Observables
        ...preloadedState,

        setPageData: (pageName: string, dataSegment: SegmentData) => {
          set((state) => {
            state.contentfulData[pageName] = {
              ...state.contentfulData[pageName],
              ...dataSegment,
            };
          });
        },

        updateFetchedExternalData: (pageName, fetchedSources) => {
          set((state) => {
            state.contentfulData[pageName].fetchedDataSources = {
              ...state.contentfulData[pageName].fetchedDataSources,
              ...fetchedSources,
            };
          });
        },

        // Update media list one time to reuse format source for the next time.
        updateMediaExternalSource: (pageName, mappedMedia) => {
          set((state) => {
            state.contentfulData[pageName].mediaList = {
              ...state.contentfulData[pageName].mediaList,
              ...mappedMedia,
            };
          });
        },
        removePostById: (pageName, entryId) => {
          set((state) => {
            state.contentfulData[pageName].posts = state.contentfulData[pageName]?.posts?.filter(
              (post) => post.entryId !== entryId,
            );
          });
        },

        setPromotions: (promotions) => {
          set((state) => {
            state.promotions = { ...state.promotions, ...promotions };
          });
        },
      };
    }),
  );
};

export enum Routes {
  CART = '/cart',
  BRANDS = '/brands',
  CATALOG = '/c',
  ERROR = '/_error',
  HOMEPAGE = '/',
  LOGIN = '/customer/account/login',
  LOGOUT = '/customer/account/logout',
  PRODUCT = '/p',
  REGISTER = '/customer/account/create',
  ACCOUNT = '/customer',
  ACCOUNT_CASHBACK = '/customer/cashback',
  ACCOUNT_DELETION = '/customer/account-deletion',
  ACCOUNT_DETAILS = '/customer/account',
  ACCOUNT_MEMBERSHIP = '/customer/membership',
  ACCOUNT_ORDERS = '/customer/order',
  ACCOUNT_REVIEWS = '/customer/review',
  ACCOUNT_WALLET = '/customer/wallet',
  SEARCH = '/search',
  SEGMENT = '/s',
  WISHLIST = '/wishlist',
  ZIS = '/store',
  PIP = '/product-index',
  PRIVACY_POLICY = '/privacy-policy',
  ABOUT_US = '/about',
  INTELLECTUAL_PROPERTY = '/intellectual-property',
  INFLUENCER = '/influencer',
  CATEGORIES = '/categories',
  ACCOUNT_PREFERENCE = '/customer/preferences',
  EXCHANGE_RETURN = '/customer/exchange_return/',
  ORDER_ITEM_DETAILS = '/customer/order/:slug',
  REQUEST_EINVOICE = '/customer/order/:slug/e-invoice',
}

export type RouteKey = `${Routes}`;

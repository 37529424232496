import withOptimizelyContext from 'context/OptimizelyContext';
import { withStoreProvider } from 'context/StoreContext';
import type { NextPage } from 'next';
import { appWithTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ReactElement, Suspense, lazy } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import withSWRConfig from 'hocs/withSWRConfig';
import useAfterAuthentication from 'hooks/useAfterAuthentication';
import compose from 'utils/compose';
import { initElasticRum } from 'utils/elastic-rum';
import * as pageTransition from 'utils/page-transition';
import { useAnonymousId } from 'utils/segment-analytic/methods';

/**
 * These are legacy styles that are still used in the CMS. Will replace them in future.
 */
import 'styles/globals.css';
import 'styles/headerv2.css';
import 'styles/headerv3.css';
import 'styles/spacing.css';
import 'styles/typography.css';

/**
 * Elastic RUM has to be execute early so that it can capture all events.
 */
initElasticRum();

const AppAsyncSection = lazy(() => import('./AppAsyncSection'));

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactElement;
};

interface LotusAppPropsWithLayout extends AppProps {
  Component: NextPageWithLayout;
}

/**
 * Init event listener for page transition
 */
pageTransition.init();

const LotusApp = ({ Component, pageProps }: LotusAppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  useAnonymousId();
  useAfterAuthentication();

  return getLayout(
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, viewport-fit=cover, shrink-to-fit=no"
        />
      </Head>

      <Suspense fallback={null}>
        <AppAsyncSection />
      </Suspense>

      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
    </>,
  );
};

export default compose(
  withStoreProvider,
  appWithTranslation,
  withSWRConfig,
  withOptimizelyContext,
)(LotusApp);

import { ZDTCustomer } from '@zalora/doraemon-ts';
import { UtmSourceValues, UtmTag } from 'constants/segment-analytic';
import { useInvokeCallbackOnceWhen } from 'hooks/useInvokeCallbackOnceWhen';
import { generateAnonymousId, getAnonymousId } from 'utils/anonymous-id';
import { getUserAgent } from 'utils/bots';
import Cookie from 'utils/cookie';
import { getLanguageByDomain } from 'utils/domains';
import { getEnvConfiguration } from 'utils/env-configuration';
import isServer from 'utils/is-server';
import { isUserSubscribedNewsLetter } from 'utils/user/news-letter/isUserSubscribedNewsLetter';
import EntryPoint from './EntryPoint';

/**
 * Local variable for caching bot agent detection
 */
let isBotAgent: boolean | undefined = undefined;

export const GENERAL_BOT_PATTERN = [
  'bot',
  'spider',
  'crawler',
  'slurp',
  'http://naver.me/spd',
  'chrome-lighthouse',
  'Google-Read-Aloud,',
  'Links (2.13; Linux 3.10.0-862.2.3.e17.x86_64 x86_64; GNU C 4.8.5; text)',
  'SEMRush',
  'BigTime',
  'LeaseWeb',
  'shopqalotusautomation',
];
export const ZALORA_AUTOMATION_BOT_PATTERN = 'shopqaautomation';

export const getBotPatternList = () => {
  const nodeEnv = getEnvConfiguration('ENV');
  const isProduction = nodeEnv === 'production';

  if (isProduction) {
    return [ZALORA_AUTOMATION_BOT_PATTERN, ...GENERAL_BOT_PATTERN];
  }

  return GENERAL_BOT_PATTERN;
};

export const isBotIdentified = () => {
  if (isBotAgent === undefined) {
    const botPatternList = getBotPatternList();
    const userAgent = getUserAgent();

    isBotAgent = botPatternList.some(
      (bot) => userAgent.includes(bot) || userAgent.toLocaleLowerCase().includes(bot),
    );
  }

  return isBotAgent;
};

export const shouldAllowTracking = () => {
  if (!isAnalyticsInWindow() || isBotIdentified()) {
    return false;
  }

  return true;
};

export const overrideAnonymousId = () => {
  if (!isAnalyticsInWindow()) {
    return;
  }

  let anonymousId = getAnonymousId();

  if (!anonymousId) {
    anonymousId = generateAnonymousId();
  }

  window.analytics.setAnonymousId(anonymousId);
};

export const getUserTraits = ({
  user,
  venture,
  emailSubscribed,
}: {
  user: ZDTCustomer.Customer;
  venture?: string;
  emailSubscribed: boolean;
}) => {
  const cookie = new Cookie();
  const activeSegment = cookie.get('userSegment');
  const language = getLanguageByDomain(window.location.hostname);

  return {
    user_id: user.Zuid,
    createdAt: user.CreatedAt,
    first_name: user.FirstName,
    birthday: user.Birthday,
    email_gender: user.Gender,
    email_subscribe: emailSubscribed ? 1 : 0,
    znow_subscribe: user?.IsVipMember ? 1 : 0,
    shop_country: venture,
    shop_language: language,
    login_status: true,
    shop_gender: activeSegment,
    is_mobile_number_verified: Boolean(user.IsVerified),
  };
};

export const getOptions = (options?: Record<string, unknown>): Record<string, unknown> => {
  const user = window.__USER__;
  const newsLetter = window.__NEWS_LETTER__;
  const context: Record<string, unknown> = {};

  if (user) {
    const venture = getEnvConfiguration('CC');
    const emailSubscribed = isUserSubscribedNewsLetter(newsLetter);

    context.traits = getUserTraits({
      user,
      venture,
      emailSubscribed,
    });
  }

  return {
    ...(options || {}),
    context,
  };
};

export const useAnonymousId = () => {
  // Update Anonymous ID
  useInvokeCallbackOnceWhen(() => {
    // override segment anonymous ID with our uuid
    overrideAnonymousId();
  });
};

export const getCipImpactUrl = (targetUrl: string) => {
  const url = new URL(targetUrl);
  const utmSource = url.searchParams.get('utm_source');

  if (utmSource === UtmSourceValues.AFFILIATE_INFLUENCER) {
    return url.toString();
  }

  if (utmSource) {
    url.search = '';
    url.searchParams.set(UtmTag.UTM_SOURCE, UtmSourceValues.NON_AFFILIATE);

    return url.toString();
  }

  return '';
};

export const getEntryPointByFromParam = (fromParam: unknown) => {
  if (typeof fromParam !== 'string') {
    return undefined;
  }

  switch (fromParam) {
    case 'header': {
      return EntryPoint.HEADER;
    }

    default: {
      return undefined;
    }
  }
};

export const addEntryPointProperty = <T extends Record<string, unknown>>(
  payload: T,
): T & { entry_point?: string } => {
  const updatedPayload = { ...payload };
  const url = new URL(window.location.href);

  if (url.searchParams.has('from')) {
    const from = url.searchParams.get('from');
    const entryPoint = getEntryPointByFromParam(from);

    if (entryPoint) {
      (updatedPayload as Record<string, unknown>).entry_point = entryPoint;
    }
  }

  return updatedPayload;
};

export const isAnalyticsInWindow = () => {
  return !isServer() && 'analytics' in window;
};

/* eslint-disable @typescript-eslint/no-namespace */

namespace FeatureFlags {
  export const enum Bob {
    ENABLE_WEB_ZERO_IHF = 'web_enable_zero_ihf',
    LOTUS_ENABLE_ZVIP_TOGGLE_CART = 'lotus_enable_zvip_toggle_cart',

    ENABLE_DJ_CATALOG = 'web_enable_dj_all_catalog',

    ENABLE_DATAJET_AUTOCOMPLETE = 'web_enable_datajet_autocomplete',

    LOTUS_ENABLE_IHF_ON_PACKAGE_HEADER = 'lotus_enable_ihf_on_package_header',
    LOTUS_ENABLE_CONTENTFUL_PERSONALISATION = 'lotus_enable_contentful_personalisation',

    LOTUS_REVIEW_TRANSLATION = 'lotus_review_translation',
    LOTUS_REVIEW_TRANSLATION_V2 = 'lotus_review_translation_v2',
    LOTUS_EARTHEDIT_TAG = 'lotus_earthedit_tag',

    ENABLE_SIMPLIFIED_CHECKOUT_STEPS = 'enable_simplified_checkout_steps',

    LOTUS_EYEFITU_DISPLAY = 'lotus_eyefitu_display',

    LOTUS_ENABLE_BEST_PRICE_GUARANTEE = 'lotus_enable_best_price_guarantee',

    LOTUS_ENABLE_SOCIAL_PROOFING = 'lotus_enable_social_proofing',
    LOTUS_ENABLE_INTERACTIVE_JOURNEY = 'lotus_enable_interactive_journey',

    LOTUS_ENABLE_PDV_404 = 'web_enable_pdv_404',

    SHIPPING_SST_2024 = 'shipping_sst_2024',

    LOTUS_SERIAL_RETURNER = 'lotus_serial_returner',
    ENABLE_HOMEPAGE_DJ_RECS = 'lotus_enable_homepage_datajet_recs',

    GIFT_CARD_LEAKAGE_PREVENTION = 'web_gc_leakage_prevention',
    LOTUS_REQUEST_EINVOICE = 'lotus_request_einvoice',
  }

  export const enum Optimizely {
    ENABLE_DJ_CATALOG = 'web_enable_dj_all_catalog',

    LOTUS_AI_DESCRIPTION = 'lotus_ai_description',
    LOTUS_EARTHEDIT_TAG = 'lotus_earthedit_tag',
    LOTUS_EYEFITU_DISPLAY = 'lotus_eyefitu_display',

    LOTUS_ENABLE_BEST_PRICE_GUARANTEE = 'lotus_enable_best_price_guarantee',
    LOTUS_SOCIAL_PROOFING = 'lotus_social_proofing',

    LOTUS_PDV_SOMETHING_SIMILAR_DJ = 'lotus_pdv_something_similar_dj',
  }

  export type BobFlag = `${Bob}`;
  export type OptimizelyFlag = `${Optimizely}`;
}

export default FeatureFlags;

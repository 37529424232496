import { BobFeatureFlagConfigByRoute } from 'types/bob-feature-flags';
import FeatureFlags from './feature-flags';
import { Routes } from './routes';

const catalogConfig = {
  server: [
    FeatureFlags.Bob.ENABLE_DJ_CATALOG,
    FeatureFlags.Bob.LOTUS_ENABLE_BEST_PRICE_GUARANTEE,
    FeatureFlags.Bob.LOTUS_ENABLE_SOCIAL_PROOFING,
    FeatureFlags.Bob.LOTUS_ENABLE_INTERACTIVE_JOURNEY,
  ],
  client: [],
};

const segmentConfig = {
  server: [
    FeatureFlags.Bob.LOTUS_ENABLE_CONTENTFUL_PERSONALISATION,
    FeatureFlags.Bob.LOTUS_ENABLE_INTERACTIVE_JOURNEY,
  ],
};

export const FF_CONFIG_BY_ROUTE: BobFeatureFlagConfigByRoute = {
  [Routes.CART]: {
    client: [
      FeatureFlags.Bob.ENABLE_WEB_ZERO_IHF,
      FeatureFlags.Bob.LOTUS_ENABLE_ZVIP_TOGGLE_CART,
      FeatureFlags.Bob.LOTUS_ENABLE_IHF_ON_PACKAGE_HEADER,
      FeatureFlags.Bob.SHIPPING_SST_2024,
      FeatureFlags.Bob.GIFT_CARD_LEAKAGE_PREVENTION,
    ],
    server: [FeatureFlags.Bob.LOTUS_ENABLE_INTERACTIVE_JOURNEY],
  },
  [Routes.WISHLIST]: {
    client: [],
  },
  [Routes.CATALOG]: catalogConfig,
  [Routes.ERROR]: {},
  [Routes.HOMEPAGE]: {},
  [Routes.PRODUCT]: {
    client: [FeatureFlags.Bob.LOTUS_EYEFITU_DISPLAY],
    server: [
      FeatureFlags.Bob.ENABLE_DJ_CATALOG, // For PDV 404
      FeatureFlags.Bob.LOTUS_REVIEW_TRANSLATION_V2,
      FeatureFlags.Bob.LOTUS_EARTHEDIT_TAG,
      FeatureFlags.Bob.LOTUS_ENABLE_BEST_PRICE_GUARANTEE,
      FeatureFlags.Bob.LOTUS_ENABLE_SOCIAL_PROOFING,
      FeatureFlags.Bob.LOTUS_ENABLE_INTERACTIVE_JOURNEY,
      FeatureFlags.Bob.LOTUS_ENABLE_PDV_404,
    ],
  },
  [Routes.ZIS]: catalogConfig,
  [Routes.SEARCH]: catalogConfig,
  [Routes.SEGMENT]: segmentConfig,
  [Routes.ACCOUNT_ORDERS]: {
    client: [FeatureFlags.Bob.LOTUS_SERIAL_RETURNER, FeatureFlags.Bob.LOTUS_REQUEST_EINVOICE],
  },

  // `common` key is used to fetch shared flags for all routes
  common: {
    server: [],
    client: [
      FeatureFlags.Bob.ENABLE_DATAJET_AUTOCOMPLETE,
      FeatureFlags.Bob.ENABLE_SIMPLIFIED_CHECKOUT_STEPS,
    ],
  },
};
